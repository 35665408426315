import React from 'react'
import { Link } from "gatsby"

import SEO from '../components/seo'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import Layout from '../components/layout'

import HeroPage from '../components/views/hero-page'

export default () => {
  return (
    <Layout>
      <SEO title="Мекей сангийн тухай" img={`/img/s1.jpg`} />
      <HeroPage
        title="Мекей сангийн тухай"
        img="s1.jpg"
      />
        <div className="page-container">
            <Container>
                <Row>
                    <Col>
                    <Breadcrumb>
                      <li className="breadcrumb-item">
                        <Link to="/">Нүүр</Link>
                      </li>
                      <Breadcrumb.Item active>Мекей сангийн тухай</Breadcrumb.Item>
                    </Breadcrumb>
                      <div>
                      Мекей сан нь 2014 оноос хойш баруун бүсийн Математикийн олимпиадыг санаачлан хэрэгжүүлж байгаа бөгөөд дараах чиглэлүүдээр үйл ажиллагаагаа өрөгжүүлэн тэлсээр байна.<br /><br />
                      -	Ардын багш, Академич А.Мекейн үйл хэргийг дэлгэрүүлэх<br />
                      -	ЕБС-ийн дунд, ахлах ангийн сурагчдын математикийн мэдлэгийг дээшлүүлэх<br />
                      -	Математикч оюутан залуусыг дэмжих<br />
                      -	Шинжлэх ухаан, мэдлэгийг түгээн дэлгэрүүлэх
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
